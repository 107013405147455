<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";

/**
 * User list component
 */
export default {
  components: { Layout, PageHeader },
  page: {
    title: "Alert",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Alert",
      items: [
        {
          text: "Alert",
        },
        {
          text: "Alert",
          active: true,
        },
      ],
      alertTicket: [],
      alertEvolution: [],
      alertTraining: [],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      //return this.userList.length;
      return 1;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    firebase.auth().onAuthStateChanged((currentUser) => {
      if (currentUser) {
        this.getAlerts();
      }
    });
  },
  methods: {
    getColor(days) {
      if (days < -14) return "table-danger";
      else if (days >= -14 && days <= -7) return "table-warning";
      else return "table-success";
    },
    dateFromTimestamp(timestamp) {
      var date = new Date(timestamp);
      return (
        date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear()
      );
    },
    daysBetweenTimestamps(date) {
      var now = new Date().getTime();
      // The number of milliseconds in one day
      const ONE_DAY = 1000 * 60 * 60 * 24;

      // Calculate the difference in milliseconds
      const differenceMs = date - now;
      // Convert back to days and return
      var diffJours = Math.round(differenceMs / ONE_DAY);
      return diffJours;
    },
    getAlerts() {
      this.loadingPlanning = true;
      var db = firebase.firestore();
      var requete = db.collection("users");
      requete = requete.where("role", "array-contains", "premium");

      if (!JSON.parse(this.isAdmin))
        requete = requete.where("coach", "==", firebase.auth().currentUser.uid);

      requete.onSnapshot((users) => {
        this.alertTicket = [];
        this.alertEvolution = [];
        this.alertTraining = [];
        users.forEach(async (user) => {
          var data = user.data();
          this.alertTicket.push({
            id: user.id,
            data: data,
            last: data.last_ticket,
          });
          this.alertEvolution.push({
            id: user.id,
            data: data,
            last: data.last_evolution,
          });
          this.alertTraining.push({
            id: user.id,
            data: data,
            last: data.last_training,
          });
          this.loadingPlanning = false;
        });

        this.alertEvolution.sort(this.GetSortOrder("last"))
        this.alertTicket.sort(this.GetSortOrder("last"))
        this.alertTraining.sort(this.GetSortOrder("last"))
      });
    },
    GetSortOrder(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  },
  },
  
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="container-fluid">
              <div class="row">
                <div class="col">
                  <h3>Alert Tickets</h3>
                  <table class="table mb-4">
                    <thead>
                      <tr>
                        <th scope="col" width="50px">#</th>
                        <th scope="col">User</th>
                        <th scope="col">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="ticket in alertTicket"
                        :key="ticket.id + 'ticket'"
                        :class="
                          getColor(
                            daysBetweenTimestamps(ticket.last)
                          )
                        "
                      >
                        <td class="align-middle">
                          <img
                            v-if="ticket.data.photo_profil"
                            :src="ticket.data.photo_profil"
                            alt
                            class="avatar-xs rounded-circle me-2"
                          />
                          <div
                            v-if="!ticket.data.photo_profil"
                            class="avatar-xs d-inline-block me-2"
                          >
                            <div
                              class="
                                avatar-title
                                bg-soft-primary
                                rounded-circle
                                text-primary
                              "
                            >
                              <i class="mdi mdi-account-circle m-0"></i>
                            </div>
                          </div>
                        </td>
                        <td class="align-middle">
                          <router-link
                            :to="{
                              name: 'Profile',
                              params: { id: ticket.id },
                            }"
                          >
                            {{ ticket.data.first_name }}
                            {{ ticket.data.last_name }}
                          </router-link>
                        </td>
                        <td>
                          <strong
                            >{{ 
                              daysBetweenTimestamps(
                                ticket.last
                              )
                            }}
                            days</strong
                          ><br />
                          <small
                            >({{
                              dateFromTimestamp(
                                ticket.last
                              )
                            }})</small
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col">
                  <h3>Alert Training</h3>
                  <table class="table mb-4">
                    <thead>
                      <tr>
                        <th scope="col" width="50px">#</th>
                        <th scope="col">User</th>
                        <th scope="col">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="training in alertTraining"
                        :key="training.id + 'training'"
                        :class="
                          getColor(
                            daysBetweenTimestamps(training.last)
                          )
                        "
                      >
                        <td class="align-middle">
                          <img
                            v-if="training.data.photo_profil"
                            :src="training.data.photo_profil"
                            alt
                            class="avatar-xs rounded-circle me-2"
                          />
                          <div
                            v-if="!training.data.photo_profil"
                            class="avatar-xs d-inline-block me-2"
                          >
                            <div
                              class="
                                avatar-title
                                bg-soft-primary
                                rounded-circle
                                text-primary
                              "
                            >
                              <i class="mdi mdi-account-circle m-0"></i>
                            </div>
                          </div>
                        </td>
                        <td class="align-middle">
                          <router-link
                            :to="{
                              name: 'Profile',
                              params: { id: training.id },
                            }"
                          >
                            {{ training.data.first_name }}
                            {{ training.data.last_name }}
                          </router-link>
                        </td>
                        <td>
                          <strong
                            >{{ 
                              daysBetweenTimestamps(
                                training.last
                              )
                            }}
                            days</strong
                          ><br />
                          <small
                            >({{
                              dateFromTimestamp(
                                training.last
                              )
                            }})</small
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col">
                  <h3>Alert Evolution</h3>
                  <table class="table mb-4">
                    <thead>
                      <tr>
                        <th scope="col" width="50px">#</th>
                        <th scope="col">User</th>
                        <th scope="col">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="evolution in alertEvolution"
                        :key="evolution.id + 'evolution'"
                        :class="
                          getColor(
                            daysBetweenTimestamps(evolution.last)
                          )
                        "
                      >
                        <td class="align-middle">
                          <img
                            v-if="evolution.data.photo_profil"
                            :src="evolution.data.photo_profil"
                            alt
                            class="avatar-xs rounded-circle me-2"
                          />
                          <div
                            v-if="!evolution.data.photo_profil"
                            class="avatar-xs d-inline-block me-2"
                          >
                            <div
                              class="
                                avatar-title
                                bg-soft-primary
                                rounded-circle
                                text-primary
                              "
                            >
                              <i class="mdi mdi-account-circle m-0"></i>
                            </div>
                          </div>
                        </td>
                        <td class="align-middle">
                          <router-link
                            :to="{
                              name: 'Profile',
                              params: { id: evolution.id },
                            }"
                          >
                            {{ evolution.data.first_name }}
                            {{ evolution.data.last_name }}
                          </router-link>
                        </td>
                        <td>
                          <div v-if="evolution.last != null">
                          <strong
                            >{{ 
                              daysBetweenTimestamps(
                                evolution.last
                              )
                            }}
                            days</strong
                          ><br />
                          <small
                            >({{
                              dateFromTimestamp(
                                evolution.last
                              )
                            }})</small
                          ></div>
                          <div v-if="evolution.last == null">
                            <strong>NEVER</strong>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
