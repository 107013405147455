var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h3',[_vm._v("Alert Tickets")]),_c('table',{staticClass:"table mb-4"},[_c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col","width":"50px"}},[_vm._v("#")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("User")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Date")])])]),_c('tbody',_vm._l((_vm.alertTicket),function(ticket){return _c('tr',{key:ticket.id + 'ticket',class:_vm.getColor(
                          _vm.daysBetweenTimestamps(ticket.last)
                        )},[_c('td',{staticClass:"align-middle"},[(ticket.data.photo_profil)?_c('img',{staticClass:"avatar-xs rounded-circle me-2",attrs:{"src":ticket.data.photo_profil,"alt":""}}):_vm._e(),(!ticket.data.photo_profil)?_c('div',{staticClass:"avatar-xs d-inline-block me-2"},[_c('div',{staticClass:"\n                              avatar-title\n                              bg-soft-primary\n                              rounded-circle\n                              text-primary\n                            "},[_c('i',{staticClass:"mdi mdi-account-circle m-0"})])]):_vm._e()]),_c('td',{staticClass:"align-middle"},[_c('router-link',{attrs:{"to":{
                            name: 'Profile',
                            params: { id: ticket.id },
                          }}},[_vm._v(" "+_vm._s(ticket.data.first_name)+" "+_vm._s(ticket.data.last_name)+" ")])],1),_c('td',[_c('strong',[_vm._v(_vm._s(_vm.daysBetweenTimestamps( ticket.last ))+" days")]),_c('br'),_c('small',[_vm._v("("+_vm._s(_vm.dateFromTimestamp( ticket.last ))+")")])])])}),0)])]),_c('div',{staticClass:"col"},[_c('h3',[_vm._v("Alert Training")]),_c('table',{staticClass:"table mb-4"},[_c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col","width":"50px"}},[_vm._v("#")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("User")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Date")])])]),_c('tbody',_vm._l((_vm.alertTraining),function(training){return _c('tr',{key:training.id + 'training',class:_vm.getColor(
                          _vm.daysBetweenTimestamps(training.last)
                        )},[_c('td',{staticClass:"align-middle"},[(training.data.photo_profil)?_c('img',{staticClass:"avatar-xs rounded-circle me-2",attrs:{"src":training.data.photo_profil,"alt":""}}):_vm._e(),(!training.data.photo_profil)?_c('div',{staticClass:"avatar-xs d-inline-block me-2"},[_c('div',{staticClass:"\n                              avatar-title\n                              bg-soft-primary\n                              rounded-circle\n                              text-primary\n                            "},[_c('i',{staticClass:"mdi mdi-account-circle m-0"})])]):_vm._e()]),_c('td',{staticClass:"align-middle"},[_c('router-link',{attrs:{"to":{
                            name: 'Profile',
                            params: { id: training.id },
                          }}},[_vm._v(" "+_vm._s(training.data.first_name)+" "+_vm._s(training.data.last_name)+" ")])],1),_c('td',[_c('strong',[_vm._v(_vm._s(_vm.daysBetweenTimestamps( training.last ))+" days")]),_c('br'),_c('small',[_vm._v("("+_vm._s(_vm.dateFromTimestamp( training.last ))+")")])])])}),0)])]),_c('div',{staticClass:"col"},[_c('h3',[_vm._v("Alert Evolution")]),_c('table',{staticClass:"table mb-4"},[_c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col","width":"50px"}},[_vm._v("#")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("User")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Date")])])]),_c('tbody',_vm._l((_vm.alertEvolution),function(evolution){return _c('tr',{key:evolution.id + 'evolution',class:_vm.getColor(
                          _vm.daysBetweenTimestamps(evolution.last)
                        )},[_c('td',{staticClass:"align-middle"},[(evolution.data.photo_profil)?_c('img',{staticClass:"avatar-xs rounded-circle me-2",attrs:{"src":evolution.data.photo_profil,"alt":""}}):_vm._e(),(!evolution.data.photo_profil)?_c('div',{staticClass:"avatar-xs d-inline-block me-2"},[_c('div',{staticClass:"\n                              avatar-title\n                              bg-soft-primary\n                              rounded-circle\n                              text-primary\n                            "},[_c('i',{staticClass:"mdi mdi-account-circle m-0"})])]):_vm._e()]),_c('td',{staticClass:"align-middle"},[_c('router-link',{attrs:{"to":{
                            name: 'Profile',
                            params: { id: evolution.id },
                          }}},[_vm._v(" "+_vm._s(evolution.data.first_name)+" "+_vm._s(evolution.data.last_name)+" ")])],1),_c('td',[(evolution.last != null)?_c('div',[_c('strong',[_vm._v(_vm._s(_vm.daysBetweenTimestamps( evolution.last ))+" days")]),_c('br'),_c('small',[_vm._v("("+_vm._s(_vm.dateFromTimestamp( evolution.last ))+")")])]):_vm._e(),(evolution.last == null)?_c('div',[_c('strong',[_vm._v("NEVER")])]):_vm._e()])])}),0)])])])])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }